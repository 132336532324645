import {
    SiFacebook,
    SiLinkedin,
    SiTwitter,
} from "@icons-pack/react-simple-icons";
import { FormattedMessage } from "react-intl";
import { usePage } from "@inertiajs/react";
import { ConditionalLink } from "@/components/nav/mobileNavTopItem";

const SocialIcon = ({ site, ...rest }) => {
    switch (site) {
        case "facebook":
            return <SiFacebook {...rest} />;
        case "linkedin":
            return <SiLinkedin {...rest} />;
        case "twitter":
            return <SiTwitter {...rest} />;
    }
};

export interface SocialLinkProps {
    id: string;
    link: string;
    site: {
        key: string;
        label: string;
        value: string;
    };
}

export default function Footer() {
    const { footer, socialLinks } = usePage().props;
    return (
        <div className="bg-black">
            <footer className="px-6 md:px-0" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">
                    <FormattedMessage id="Footer" />
                </h2>
                <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-12 lg:px-8">
                    <div className="grid grid-cols-1 gap-12 sm:grid-cols-10 sm:gap-7 lg:grid-cols-12 lg:gap-12">
                        {footer.map((column) => (
                            <div
                                className="sm:col-span-2"
                                key={column.page.title}
                            >
                                <h3 className="text-base font-bold text-gray-50">
                                    {column.page.title}
                                </h3>
                                <ul
                                    role="list"
                                    className="mt-4 space-y-2 text-gray-100"
                                >
                                    {column.children.map((item) => (
                                        <li key={item.page.title}>
                                            <ConditionalLink
                                                inertia={item?.page?.inertia}
                                                href={item.page.url}
                                                className="text-base hover:text-white transition"
                                            >
                                                {item.page.title}
                                            </ConditionalLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        <div className="sm:col-span-2 lg:col-span-4 ">
                            <div className="text-gray-100 flex space-x-4 sm:justify-end">
                                {socialLinks.map((item) => (
                                    <a
                                        key={item.id}
                                        href={item.link}
                                        className="hover:text-gray-200"
                                    >
                                        <span className="sr-only">
                                            {item.site.label}
                                        </span>
                                        <SocialIcon
                                            site={item.site.value}
                                            className="size-6"
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="space-y-6 sm:space-y-0 sm:flex sm:items-end sm:justify-between mt-24">
                        <div>
                            <a href={route("home")}>
                                <img
                                    className="h-10"
                                    src="/images/voice-logo-white.svg"
                                    alt="Voice Logo"
                                />
                            </a>
                        </div>
                        <div className="sm:flex sm:justify-end text-sm text-gray-100">
                            <span className="hidden sm:inline sm:mr-1">
                                <FormattedMessage id="Copyright" />
                            </span>
                            &copy; {new Date().getFullYear()}{" "}
                            <FormattedMessage id="Newcastle University. All rights reserved." />
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
